<template lang="pug">
.flex.items-center.justify-center.mt-6.mb-6
  .circle
    svg.circle__svg(width="160", height="160")
      circle.circle__progress.circle__progress--path(cx="80", cy="80", r="68")
      circle.circle__progress.circle__progress--fill(
        cx="80",
        cy="80",
        r="68",
        ref="circle_fill"
      )
  .counter {{ percent }}
    span %

.step-description.mb-10
  XyzTransitionGroup.flex.justify-center(
    appear,
    xyz="fade right out-left delay-5 out-delay-0"
  )
    p.absolute.text-center(:key="1", v-if="text_num == 1") Procesando datos introducidos...
    p.absolute.text-center(:key="2", v-if="text_num == 2") Cálculo del perfil dietético...
    p.absolute.text-center(:key="3", v-if="text_num == 3") Calculando cantidad de calorias...
    p.absolute.text-center(:key="4", v-if="text_num == 4") Seleccionando recetas adecuadas...
    p.absolute.text-center(:key="5", v-if="text_num == 5") ¡Tu dieta keto personalizada esta lista!
</template>
<script>
export default {
  name: "StepLoading",
  data: () => ({
    transitionDuration: 12000,
    percent: 0,
    text_num: 1,
  }),
  mounted() {
    const progress = this.$refs.circle_fill;
    let radius = progress.r.baseVal.value;
    let circumference = 2 * Math.PI * radius;
    progress.style.setProperty(
      "--transitionDuration",
      `${this.transitionDuration}ms`
    );
    progress.style.setProperty("--initialStroke", circumference);
    setTimeout(() => (progress.style.strokeDashoffset = 0), 100);
    this.activateCounter();
  },
  methods: {
    activateCounter() {
      const interval = this.transitionDuration / 100;
      let counter = 0;
      let increaseInterval = setInterval(() => {
        if (counter === 100) {
          window.clearInterval(increaseInterval);
          this.redirectToAnalysis();
        }
        if (counter === 1) this.text_num = 1;
        if (counter === 30) this.text_num = 2;
        if (counter === 50) this.text_num = 3;
        if (counter === 70) this.text_num = 4;
        if (counter === 95) this.text_num = 5;

        this.percent = counter;
        counter++;
      }, interval);
    },
    redirectToAnalysis() {
      setTimeout(() => {
        this.$router.push({ name: "Analysis" });
      }, 1200);
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.counter {
  @apply font-semibold text-5xl absolute pr-3 text-left;
  span {
    @apply text-xl absolute;
  }
}

.circle {
  position: relative;
}

.circle__svg {
  transform: rotate(-90deg);
}

.circle__progress {
  fill: none;
  stroke-width: 8;
  stroke-opacity: 0.3;
  stroke-linecap: round;
}

.circle__progress {
  stroke: #71aa00;
}

.circle__progress--fill {
  --initialStroke: 0;
  --transitionDuration: 0;
  stroke-opacity: 1;
  stroke-dasharray: var(--initialStroke);
  stroke-dashoffset: var(--initialStroke);
  transition: stroke-dashoffset var(--transitionDuration)
    cubic-bezier(0.22, 0.5, 0.92, 0.57);
  filter: drop-shadow(0px 0px 6px rgba(77, 124, 15, 0.5));
}

.descriptions-enter-active,
.descriptions-leave-active {
  display: inline-block;
  transition: all 1s ease;
}
.descriptions-enter-from,
.descriptions-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.step-description {
  p {
    @apply font-medium text-green-700;
  }
}
</style>